import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { UserContext } from '/src/contexts/UserContext'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import submitStandardForm from '/src/functions/forms/submitStandardForm'

const Alert = loadable(() => import('/src/components/Basic/Alert'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const Radio = loadable(() => import('/src/components/Form/Radio'))
const RequiredText = loadable(() => import('/src/components/Form/Required/RequiredText'))
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))
const TextAreaField = loadable(() => import('/src/components/Form/TextAreaField'))
const TextField = loadable(() => import('/src/components/Form/TextField'))

const validationSchema = Yup.object({
    name: Yup.string(),
    agentService: Yup.string().required('This field is required'),
    applicationProcess: Yup.string().required('This field is required'),
    requirements: Yup.string().required('This field is required'),
    trustService: Yup.string().required('This field is required'),
    trustProcess: Yup.string().required('This field is required'),
    reason: Yup.string(),
    consent: Yup.string().required('This field is required')
})

const CustomerFeedbackForm = ({ disclaimer }) => {
    const [user] = useContext(UserContext)

    const [isSubmitted, setSubmitted] = useState(false)

    // Grab plid parameter from URL
    const leadId = user.plid

    return (
        <Formik
            initialValues={{
                name: '',
                agentService: '',
                applicationProcess: '',
                requirements: '',
                trustService: '',
                trustProcess: '',
                reason: '',
                consent: ''
            }}
            onSubmit={async (values, actions) => {
                const firebaseData = {
                    createdAt: new Date(),
                    leadId: leadId,
                    name: values.name,
                    agentService: values.agentService,
                    applicationProcess: values.applicationProcess,
                    requirements: values.requirements,
                    trustService: values.trustService,
                    trustProcess: values.trustProcess,
                    reason: values.reason,
                    consent: values.consent
                }

                const submitOptions = {
                    actions: actions,
                    firebaseCollection: 'customer-feedback-form',
                    firebaseData: firebaseData,
                    confirmationEmail: [
                        process.env.GATSBY_CUSTOMER_FEEDBACK_EMAIL_ADDRESS,
                        process.env.GATSBY_CUSTOMER_FEEDBACK_EMAIL_ADDRESS_2
                    ],
                    emailTemplate: 'customer-feedback-form-template',
                    redirect: '/customer-feedback-survey-thanks'
                }

                setSubmitted(true)

                await submitStandardForm(submitOptions)
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <RequiredText />

                    <TextField
                        label={'What is your name?'}
                        name={'name'}
                        placeholder={'This field is optional'}
                        isSubmitting={isSubmitting}
                        isSubmitted={isSubmitted}
                    />

                    <hr />

                    <FieldSet
                        variant={'wrap'}
                        legend={
                            'How would you describe the customer service experience you received in dealing with the agent who completed your life insurance application?'
                        }
                        groupName={'agentService'}
                        required
                    >
                        <FieldSpacer grow={'third'}>
                            <Radio
                                label={'Excellent'}
                                groupName={'agentService'}
                                name={'agentServiceExcellent'}
                                value={'Excellent'}
                            />
                        </FieldSpacer>

                        <FieldSpacer grow={'third'}>
                            <Radio label={'Good'} groupName={'agentService'} name={'agentServiceGood'} value={'Good'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'third'}>
                            <Radio label={'Fair'} groupName={'agentService'} name={'agentServiceFair'} value={'Fair'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'third'}>
                            <Radio
                                label={'Below Expectation'}
                                groupName={'agentService'}
                                name={'agentServiceBelowExpectation'}
                                value={'Below Expectation'}
                            />
                        </FieldSpacer>

                        <FieldSpacer grow={'third'}>
                            <Radio label={'Poor'} groupName={'agentService'} name={'agentServicePoor'} value={'Poor'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'third'}>
                            <Radio label={'N/A'} groupName={'agentService'} name={'agentServiceNa'} value={'N/A'} />
                        </FieldSpacer>
                    </FieldSet>

                    <hr />

                    <FieldSet
                        legend={'Was the application process clearly explained to you?'}
                        groupName={'applicationProcess'}
                        required
                    >
                        <FieldSpacer grow={'stretch'}>
                            <Radio
                                label={'Yes'}
                                groupName={'applicationProcess'}
                                name={'applicationProcessYes'}
                                value={'Yes'}
                            />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <Radio
                                label={'Not sure'}
                                groupName={'applicationProcess'}
                                name={'applicationProcessNotSure'}
                                value={'Not sure'}
                            />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <Radio
                                label={'No'}
                                groupName={'applicationProcess'}
                                name={'applicationProcessNo'}
                                value={'No'}
                            />
                        </FieldSpacer>
                    </FieldSet>

                    <hr />

                    <FieldSet
                        legend={'Do you feel that the product(s) provided meet(s) your requirements?'}
                        groupName={'requirements'}
                        required
                    >
                        <FieldSpacer grow={'stretch'}>
                            <Radio label={'Yes'} groupName={'requirements'} name={'requirementsYes'} value={'Yes'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <Radio
                                label={'Not sure'}
                                groupName={'requirements'}
                                name={'requirementsNotSure'}
                                value={'Not sure'}
                            />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <Radio label={'No'} groupName={'requirements'} name={'requirementsNo'} value={'No'} />
                        </FieldSpacer>
                    </FieldSet>

                    <hr />

                    <FieldSet
                        variant={'wrap'}
                        legend={
                            'If you have placed your policy in trust, please describe the customer service experience you received in dealing with the agent who completed your trust paperwork'
                        }
                        groupName={'trustService'}
                        required
                    >
                        <FieldSpacer grow={'third'}>
                            <Radio
                                label={'Excellent'}
                                groupName={'trustService'}
                                name={'trustServiceExcellent'}
                                value={'Excellent'}
                            />
                        </FieldSpacer>

                        <FieldSpacer grow={'third'}>
                            <Radio label={'Good'} groupName={'trustService'} name={'trustServiceGood'} value={'Good'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'third'}>
                            <Radio label={'Fair'} groupName={'trustService'} name={'trustServiceFair'} value={'Fair'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'third'}>
                            <Radio
                                label={'Below Expectation'}
                                groupName={'trustService'}
                                name={'serviceBelowExpectation'}
                                value={'Below Expectation'}
                            />
                        </FieldSpacer>

                        <FieldSpacer grow={'third'}>
                            <Radio label={'Poor'} groupName={'trustService'} name={'trustServicePoor'} value={'Poor'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'third'}>
                            <Radio label={'N/A'} groupName={'trustService'} name={'trustServiceNa'} value={'N/A'} />
                        </FieldSpacer>
                    </FieldSet>

                    <hr />

                    <FieldSet
                        legend={'Was the trust process fully explained to you?'}
                        groupName={'trustProcess'}
                        required
                    >
                        <FieldSpacer grow={'stretch'}>
                            <Radio label={'Yes'} groupName={'trustProcess'} name={'trustProcessYes'} value={'Yes'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <Radio
                                label={'Not sure'}
                                groupName={'trustProcess'}
                                name={'trustProcessNotSure'}
                                value={'Not sure'}
                            />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <Radio label={'No'} groupName={'trustProcess'} name={'trustProcesssNo'} value={'No'} />
                        </FieldSpacer>
                    </FieldSet>

                    <hr />

                    <TextAreaField
                        label={`If you have answered 'no'/'not sure' to any of the questions above, please provide any further information along with any other feedback that may be relevant here:`}
                        name={'reason'}
                        placeholder={'This field is optional'}
                        isSubmitting={isSubmitting}
                        isSubmitted={isSubmitted}
                    />

                    <hr />

                    <FieldSet
                        legend={
                            'Thank you for taking the time to provide us with feedback. We sometimes like to contact our customers to review the feedback provided. Would you be happy to be contacted further?'
                        }
                        groupName={'consent'}
                        required
                    >
                        <FieldSpacer grow={'stretch'}>
                            <Radio label={'Yes'} groupName={'consent'} name={'consentYes'} value={'Yes'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <Radio label={'No'} groupName={'consent'} name={'consentNo'} value={'No'} />
                        </FieldSpacer>
                    </FieldSet>

                    {disclaimer && <Alert content={disclaimer} />}

                    <CtaContainer align={'right'}>
                        <SubmitButton
                            initialText={'Submit Survey'}
                            sentText={'Survey submitted!'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            disabled={!(isValid && dirty)}
                        />
                    </CtaContainer>
                </Form>
            )}
        </Formik>
    )
}

CustomerFeedbackForm.propTypes = {
    /**
     * Disclaimer
     */
    disclaimer: PropTypes.string
}

CustomerFeedbackForm.defaultProps = {
    disclaimer: '<p>This is the disclaimer message</p>'
}

export default CustomerFeedbackForm
